/* eslint-disable react/no-multi-comp */
import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Dialog, Slide } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import { setDialogClose } from 'store/actions';

import {
  CustomersEditDialog,
  CustomerDeleteDialog,
} from 'views/Customer/components';

import {
  ChargeDeleteDialog,
  ChargeViewDialog,
} from 'views/ChargeReport/components';

import {
  BankAccountCreateDialog,
  BankAccountDeleteDialog,
} from 'views/BankAccount/components';

import {
  SalesFilterAmountDialog,
  SalesFilterDateDialog,
  SalesFilterPaymentTypeDialog,
  SalesFilterStatusDialog,
  SalesViewDialog,
} from 'views/Sales/components';
import { ProductEditDialog } from 'views/Products/components/ProductsList/components';
import { SubscriptionViewDialog } from 'views/Subscriptions/components/SubscriptionsList/components';
import { PlanEditDialog } from 'views/Plans/components/PlansList/components';
import {
  CouponEditDialog,
  CouponViewDialog,
} from 'views/Coupons/components/CouponsList/components';
import { CheckoutEditDialog } from 'views/Checkouts/components/CheckoutsList/components';
import { OfferEditDialog } from 'views/Offers/components/OffersList/components';
import TaxInvoicesEditDialog from 'views/tax-invoices/components/tax-invoices-list/components/tax-invoices-edit-dialog/tax-invoices-edit-dialog.component';
import TaxInvoiceConfigDialog from 'views/tax-invoices/components/tax-invoices-list/components/tax-invoice-config-dialog';
import {
  MarketplaceUserEditDialog,
  MarketplaceUserViewDialog,
} from 'views/marketplace-user/components/marketplace-user-list/components';
import MarketplaceUserDeleteDialog from 'views/marketplace-user/components/marketplace-user-list/components/marketplace-user-delete-dialog';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogFlex = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, type, full_screen, max_width } = useSelector(
    state => state.dialogState
  );

  const handleCloseDialog = () => {
    dispatch(setDialogClose());
  };

  const render = () => {
    switch (type) {
      // Customer
      case 'createCustomer':
        return <CustomersEditDialog />;
      case 'editCustomer':
        return <CustomersEditDialog />;
      case 'deleteCustomer':
        return <CustomerDeleteDialog />;

      // Charge
      case 'viewCharge':
        return <ChargeViewDialog />;
      case 'deleteCharge':
        return <ChargeDeleteDialog />;

      // Bank account
      case 'createBankAccount':
        return <BankAccountCreateDialog />;
      case 'deleteBankAccount':
        return <BankAccountDeleteDialog />;

      // Sales
      case 'viewSale':
        return <SalesViewDialog />;
      case 'saleFilterDate':
        return <SalesFilterDateDialog />;
      case 'saleFilterStatus':
        return <SalesFilterStatusDialog />;
      case 'saleFilterPaymentType':
        return <SalesFilterPaymentTypeDialog />;
      case 'saleFilterAmount':
        return <SalesFilterAmountDialog />;

      // Product
      case 'createProduct':
        return <ProductEditDialog />;
      case 'editProduct':
        return <ProductEditDialog />;

      // Subscription
      case 'viewSubscription':
        return <SubscriptionViewDialog />;

      // Plan
      case 'createPlan':
        return <PlanEditDialog />;
      case 'editPlan':
        return <PlanEditDialog />;

      // Checkout
      case 'createCheckout':
        return <CheckoutEditDialog />;
      case 'editCheckout':
        return <CheckoutEditDialog />;

      // Offer
      case 'createOffer':
        return <OfferEditDialog />;
      case 'editOffer':
        return <OfferEditDialog />;

      // Coupon
      case 'createCoupon':
      case 'editCoupon':
        return <CouponEditDialog />;
      case 'viewCoupon':
        return <CouponViewDialog />;

      // Tax Invoice
      case 'createTaxInvoice':
      case 'editTaxInvoice':
        return <TaxInvoicesEditDialog />;
      case 'viewTaxInvoice':
        return <TaxInvoicesEditDialog />;
      case 'configTaxInvoice':
        return <TaxInvoiceConfigDialog />;

      case 'createMarketplaceUser':
      case 'editMarketplaceUser':
        return <MarketplaceUserEditDialog />;
      case 'viewMarketplaceUser':
        return <MarketplaceUserViewDialog />;
      case 'deleteMarketplaceUser':
        return <MarketplaceUserDeleteDialog />;

      default:
        return <div />;
    }
  };

  if (type) {
    return (
      <Dialog
        TransitionComponent={Transition}
        classes={{
          paper: classes.paper,
        }}
        fullScreen={full_screen}
        maxWidth={max_width}
        onClose={handleCloseDialog}
        open={open}
      >
        {render()}
      </Dialog>
    );
  }

  return <div />;
};

export default DialogFlex;
