import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  // App bar
  appBar: {
    position: 'relative',
  },
  appBarTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff',
  },

  container: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
    margin: 0,
    width: '100%',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  actionButton: {
    flex: 1,
    maxWidth: 320,
  },
  inputDisabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
  // Select
  selectMenuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  selectDisabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
  selectIcon: {
    color: theme.palette.text.primary,
  },
  selectMenuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  selectItemSelected: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default} !important`,
  },

  // Pickers
  rootDialog: {
    backgroundColor: theme.palette.background.dark2,
  },
  buttonPicker: {
    color: theme.palette.white,
    backgroundColor: 'inherit',
  },
}));

export default useStyles;
