import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import React from 'react';

import { Metadata } from 'components/utils/Metadata';

import { useTaxInvoiceConfigDialog } from './tax-invoice-config-dialog.hook';
import useStyles from './tax-invoice-config-dialog.styles';

import { ETaxInvoiceConfigProvider } from 'types/entities/tax-invoice-config';

function TaxInvoiceConfigDialog() {
  const classes = useStyles();

  const {
    taxInvoiceConfig,
    formState,
    handleDialogClose,
    handleFormChange,
    handleMetadataChange,
    handleSubmit,
    hasError,
  } = useTaxInvoiceConfigDialog();

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.appBarTitle} variant="h5">
            Configurar nota fiscal
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid || formState.disabled}
            onClick={handleSubmit}
          >
            Salvar
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do cupom</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            SelectProps={{
              classes: {
                icon: classes.selectIcon,
                disabled: classes.selectDisabled,
              },
              MenuProps: {
                classes: {
                  paper: classes.selectMenuList,
                },
              },
            }}
            disabled={formState.disabled}
            error={hasError('provider')}
            fullWidth
            helperText={hasError('provider') && formState.errors.provider}
            id="provider"
            label="Provedor"
            name="provider"
            onChange={handleFormChange}
            select
            value={formState.values.provider || ''}
            variant="outlined"
          >
            <Mui.MenuItem
              button
              disabled
              value={ETaxInvoiceConfigProvider.ENOTAS}
            >
              ENOTAS
            </Mui.MenuItem>
            <Mui.MenuItem
              button
              disabled
              value={ETaxInvoiceConfigProvider.NFE_IO}
            >
              NFE.IO
            </Mui.MenuItem>
            <Mui.MenuItem button value={ETaxInvoiceConfigProvider.NOTAZZ}>
              NOTAZZ
            </Mui.MenuItem>
            <Mui.MenuItem
              button
              disabled
              value={ETaxInvoiceConfigProvider.RECEBAFACIL}
            >
              RECEBA FÁCIL
            </Mui.MenuItem>
          </Mui.TextField>
        </Mui.Grid>
        {formState.values.provider && (
          <>
            <Mui.Grid item md={4} sm={6} xs={12}>
              <Mui.TextField
                InputLabelProps={{
                  classes: {
                    disabled: classes.inputDisabled,
                  },
                }}
                InputProps={{
                  classes: {
                    disabled: classes.inputDisabled,
                  },
                }}
                disabled={formState.disabled}
                error={hasError('apiKey')}
                fullWidth
                helperText={hasError('apiKey') && formState.errors.apiKey}
                id="apiKey"
                label="Chave da API"
                name="apiKey"
                onChange={handleFormChange}
                type="text"
                value={formState.values.apiKey}
                variant="outlined"
              />
            </Mui.Grid>

            <Mui.Grid item md={4} sm={6} xs={12}>
              <Mui.TextField
                InputLabelProps={{
                  classes: {
                    disabled: classes.selectDisabled,
                  },
                }}
                SelectProps={{
                  classes: {
                    icon: classes.selectIcon,
                    disabled: classes.selectDisabled,
                  },
                  MenuProps: {
                    classes: {
                      paper: classes.selectMenuList,
                    },
                  },
                }}
                disabled={formState.disabled}
                error={hasError('active')}
                fullWidth
                helperText={hasError('active') && formState.errors.active}
                label="Ativo"
                name="active"
                onChange={handleFormChange}
                select
                value={formState.values.active}
                variant="outlined"
              >
                <Mui.MenuItem button value="true">
                  SIM
                </Mui.MenuItem>
                <Mui.MenuItem button value="false">
                  NÃO
                </Mui.MenuItem>
              </Mui.TextField>
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Metadados</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item lg={8} md={10} xs={12}>
              <Metadata
                metadata={taxInvoiceConfig?.metadata}
                onChange={handleMetadataChange}
              />
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
}

export default TaxInvoiceConfigDialog;
