import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Grid,
} from '@material-ui/core';
import { getDisplayMoney } from 'helpers';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0 auto',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

const ConfirmCharge = () => {
  const classes = useStyles();

  const {
    chargeCustomers,
    payment_types_accepted,
    installments,
    expiration_date,
    expiration_options,
    payment_limit_date,
    late_fee,
    interest,
    discount_options,
    discount,
    amount,
  } = useSelector(state => state.chargeState);

  const getDisplayDiscountLimitDate = () => {
    if (discount.limit_date === 0) {
      return 'a data de vencimento';
    } else {
      return `${discount.limit_date} dia(s) antes do vencimento`;
    }
  };

  const renderDisplayCustomersName = () =>
    chargeCustomers.map(customer => customer.name).join('; ');

  return (
    <React.Fragment>
      <CardHeader
        subheader={
          <Typography color="textSecondary" variant="h5">
            Verifique atentamente os dados.
          </Typography>
        }
        title={<Typography variant="h3">Cobrança quase finalizada</Typography>}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item md={3} xs={12}>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Valor total
              </Typography>
              <Typography color="primary" variant="h3">
                {getDisplayMoney(amount)}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Parcelado
              </Typography>
              <Typography color="textPrimary" variant="h6">
                {installments}x de {getDisplayMoney(amount / installments)}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Vencimento
              </Typography>
              <Typography color="textPrimary" variant="h6">
                {moment(expiration_date).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Pagamento via
              </Typography>
              <Typography color="textPrimary" variant="h6">
                {payment_types_accepted.billet ? 'Boleto bancário' : ''}
              </Typography>
              <Typography color="textPrimary" variant="h6">
                {payment_types_accepted.credit_card ? 'Cartão de crédito' : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={1} xs={12}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item md={8} xs={12}>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Cliente(s)
              </Typography>
              <Typography color="textPrimary" variant="h6">
                {renderDisplayCustomersName()}
              </Typography>
            </Grid>
            {expiration_options && (
              <Grid className={classes.marginTop} container direction="column">
                <Typography color="textSecondary" variant="h5">
                  Opções de vencimento
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  Receber até {payment_limit_date} dia(s) após a data de
                  vencimento com multa de {late_fee.percentage || '0'}% do valor
                  e juros de {interest.percentage || '0'}% ao mês
                </Typography>
              </Grid>
            )}
            {discount_options && (
              <Grid className={classes.marginTop} container direction="column">
                <Typography color="textSecondary" variant="h5">
                  Opções de desconto
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  Desconto de {getDisplayMoney(discount.amount)} até{' '}
                  {getDisplayDiscountLimitDate()}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
};

export default ConfirmCharge;
