import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import moment from 'moment';

import type { IBoleto, ICard, ITransaction } from 'types/entities';
import type { ICardBrandIconProps } from 'components';
import { CardBrandIcon } from 'components';
import { setSale, setDialogClose, setDialogType } from 'store/actions';
import {
  getDisplayMoney,
  getDisplayPhoneNumber,
  getDisplayEmail,
} from 'helpers';

import useStyles from './SalesViewDialog.styles';
import { useSale } from 'hooks/useSale';

const SalesViewDialog = () => {
  const classes = useStyles();
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = useSale();
  const { sale } = state;

  const [isRefunding, setIsRefunding] = React.useState<boolean>(false);

  async function handleRefund() {
    try {
      if (!sale) {
        return;
      }

      setIsRefunding(true);
      const transaction = await service.refund({
        transactionId: sale.id,
      });

      dispatch(setSale(transaction));
    } finally {
      setIsRefunding(false);
    }
  }

  const handleDialogClose = () => {
    dispatch([setSale(null), setDialogClose(), setDialogType(null)]);
  };

  const getDisplayInstallmentOrCash = () => {
    if (!sale) {
      return '';
    }

    const amount = getDisplayMoney(sale.amount / 100);

    if (!sale?.installment_plan) {
      return `${amount} à vista`;
    } else {
      if (sale?.installment_plan === 1) {
        return `${amount} à vista`;
      } else {
        return `${sale?.installment_plan}x de ${amount}`;
      }
    }
  };

  const getDisplaySaleStatus = (status: ITransaction['status']) => {
    switch (status) {
      case 'new':
        return 'Criada';
      case 'failed':
        return 'Falhada';
      case 'succeeded':
        return 'Aprovada';
      case 'pending':
        return 'Pendente';
      case 'canceled':
        if (sale?.voided) {
          return 'Estornada';
        }

        return 'Cancelada';
      case 'dispute':
        return 'Disputada';
      case 'charged_back':
        return 'Chargeback';
      default:
        return '';
    }
  };

  const getDisplayOperationType = (operation_type: string) => {
    switch (operation_type) {
      case 'created':
        return 'Criada';
      case 'authorization':
        return 'Autorizada';
      case 'risk_analysis':
        return 'Análise de risco';
      case 'failed':
        return 'Falhada';
      case 'void':
        return 'Cancelada';
      default:
        return '';
    }
  };

  const renderSaleInfoByPaymentMethod = () => {
    if (sale?.payment_type === 'boleto') {
      const boleto = sale.payment_method as IBoleto;

      return (
        <React.Fragment>
          <Mui.Grid item md={3} sm={4} xs={12}>
            <Mui.Typography variant="body1">
              <strong>Forma de pagamento: </strong>
              Boleto
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item md={3} sm={4} xs={12}>
            <Mui.Typography variant="body1">
              <strong>Data de vencimento: </strong>
              {moment(boleto.expiration_date).format('DD/MM/YYYY')}
            </Mui.Typography>
          </Mui.Grid>
        </React.Fragment>
      );
    } else if (sale?.payment_type === 'credit') {
      const card = sale.payment_method as ICard;

      return (
        <React.Fragment>
          <Mui.Grid item md={3} sm={4} xs={12}>
            <Mui.Typography variant="body1">
              <strong>Forma de pagamento: </strong>
              Cartão de crédito
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item md={3} sm={4} xs={12}>
            <Mui.Typography
              className={classes.cardBrandContainer}
              component="div"
              variant="body1"
            >
              <strong>Bandeira do cartão: </strong>
              <div className={classes.cardBrandIconContainer}>
                {card.card_brand}
                <CardBrandIcon
                  brand={
                    card.card_brand.toLowerCase() as ICardBrandIconProps['brand']
                  }
                />
              </div>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item md={3} sm={4} xs={12}>
            <Mui.Typography variant="body1">
              <strong>Data prevista para recebimento: </strong>
              {moment(sale.expected_on).format('DD/MM/YYYY')}
            </Mui.Typography>
          </Mui.Grid>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  const renderCharge = () => {
    if (sale?.metadata?.charge) {
      return (
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>ID da cobrança: </strong>
            {sale.metadata.charge}
          </Mui.Typography>
        </Mui.Grid>
      );
    }

    return null;
  };

  const renderHistory = () => {
    if (sale?.history && sale.history.length > 0) {
      const history_list = sale.history.map(sale_history => {
        return (
          <React.Fragment key={sale_history.id}>
            <Mui.Grid item md={3} sm={4} xs={12}>
              <Mui.Typography variant="body1">
                <strong>Data: </strong>
                {moment(sale_history.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item md={3} sm={4} xs={12}>
              <Mui.Typography variant="body1">
                <strong>Operação: </strong>
                {getDisplayOperationType(sale_history.operation_type)}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item md={3} sm={4} xs={12}>
              <Mui.Typography variant="body1">
                <strong>Status da operação: </strong>
                {getDisplaySaleStatus(sale_history.status)}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item md={3} sm={4} xs={12}>
              <Mui.Typography variant="body1">
                <strong>Valor: </strong>
                {getDisplayMoney(parseFloat(sale_history.amount))}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
          </React.Fragment>
        );
      });

      return (
        <React.Fragment>
          <Mui.Grid item xs={12}>
            <Mui.Divider />
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Mui.Typography variant="h4">Histórico da venda</Mui.Typography>
          </Mui.Grid>
          {history_list}
        </React.Fragment>
      );
    }

    return null;
  };

  if (!sale) {
    return null;
  }

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            {`Venda (${getDisplaySaleStatus(sale.status).toUpperCase()})`}
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="end"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>
        <div className={classes.actionsContainer}>
          <div className={classes.actionButton}>
            <Mui.Button
              color="secondary"
              disabled={isRefunding || sale.status !== 'succeeded'}
              fullWidth
              onClick={handleRefund}
              variant="contained"
            >
              Estornar
            </Mui.Button>
          </div>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Emitir carta de estorno
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>
        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do pagador</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Nome: </strong>
            {sale.customer.name}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>E-mail: </strong>
            {getDisplayEmail(sale.customer.email)}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Contato: </strong>
            {getDisplayPhoneNumber(sale.customer.phone_number)}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados da venda</Mui.Typography>
        </Mui.Grid>
        {renderCharge()}
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Valor: </strong>
            {getDisplayInstallmentOrCash()}
          </Mui.Typography>
        </Mui.Grid>
        {renderSaleInfoByPaymentMethod()}
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data da venda: </strong>
            {moment(sale.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>
        {renderHistory()}
      </Mui.Grid>
    </React.Fragment>
  );
};

export default SalesViewDialog;
