import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import clsx from 'clsx';
import {
  setChargeBillet,
  setChargeCreditCard,
  setChargeAmount,
  setChargeInstallments,
  setChargeExpirationDate,
  setChargeExpirationOptions,
  setChargePaymentLimitDate,
  setChargeLateFeePercentage,
  setChargeInterestPercentage,
  setChargeDiscountOptions,
  setChargeDiscountLimitDate,
  setChargeDiscountAmount,
} from 'store/actions';
import { getDisplayMoney } from 'helpers';
import { makeStyles } from '@material-ui/styles';
import {
  CardHeader,
  Typography,
  Divider,
  CardContent,
  Checkbox,
  Grid,
  TextField,
  Paper,
  MenuItem,
  Switch,
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { InputCurrency } from 'components';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0 auto',
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
  paperDisabled: {
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.7,
  },
  paperSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  checkbox: {
    padding: 'auto 0',
  },
  checked: {
    color: theme.palette.white,
  },
  cardHeaderAction: {
    alignSelf: 'flex-end',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  disabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemSelected: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  subTitle: {
    marginTop: theme.spacing(1),
  },
  rootDialog: {
    backgroundColor: theme.palette.background.dark2,
  },
  buttonPicker: {
    color: theme.palette.white,
    backgroundColor: 'inherit',
  },
  popover: {
    pointerEvents: 'none',
  },
  paperPopover: {
    backgroundColor: theme.palette.background.dark2,
    padding: theme.spacing(1),
    maxWidth: 310,
  },
  usersWithoutAddressTitle: {
    padding: theme.spacing(2),
  },
}));

const dispatchEventChange = {
  billet: setChargeBillet,
  credit_card: setChargeCreditCard,
  amount: setChargeAmount,
  installments: setChargeInstallments,
  expiration_date: setChargeExpirationDate,
  expiration_options: setChargeExpirationOptions,
  payment_limit_date: setChargePaymentLimitDate,
  late_fee_percentage: setChargeLateFeePercentage,
  interest_percentage: setChargeInterestPercentage,
  discount_options: setChargeDiscountOptions,
  discount_limit_date: setChargeDiscountLimitDate,
  discount_amount: setChargeDiscountAmount,
};

const ChargeConfig = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorElementPopover, setAnchorElementPopover] = useState(null);
  const popoverIsOpen = Boolean(anchorElementPopover);
  const {
    payment_types_accepted,
    amount,
    installments,
    expiration_date,
    expiration_options,
    discount_options,
    late_fee,
    interest,
    discount,
    payment_limit_date,
    chargeCustomers,
  } = useSelector(state => state.chargeState);

  const customersWithoutAddress = useMemo(
    () => chargeCustomers.filter(customer => !customer.postal_code),
    [chargeCustomers]
  );

  const handleChange = event => {
    const handleEvent = dispatchEventChange[event.target.name];
    if (event.target.type === 'checkbox') {
      dispatch(handleEvent(event.target.checked));
    } else {
      dispatch(handleEvent(event.target.value));
    }
  };

  const handleChangeExpirationDate = dateMoment => {
    const expiration_date = dateMoment.toDate();

    dispatch(setChargeExpirationDate(expiration_date));
  };

  const handlePopoverOpen = event =>
    customersWithoutAddress.length > 0 &&
    setAnchorElementPopover(event.currentTarget);
  const handlePopoverClose = () => setAnchorElementPopover(null);

  return (
    <React.Fragment>
      <Popover
        anchorEl={anchorElementPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
        classes={{
          paper: classes.paperPopover,
        }}
        disableRestoreFocus
        id="mouse-over-popover"
        onClose={handlePopoverClose}
        open={popoverIsOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box>
          <Typography className={classes.usersWithoutAddressTitle} variant="h6">
            Os seguintes usuários estão sem endereço
          </Typography>
          <Divider />
          <List dense>
            {customersWithoutAddress.map(customer => (
              <ListItem key={customer.id}>
                <ListItemText primary={customer.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>

      <CardHeader
        subheader={
          <Typography
            className={classes.subTitle}
            color="textSecondary"
            variant="h5"
          >
            Configure valor, formas de pagamento, parcelamento e data de
            vencimento.
          </Typography>
        }
        title={<Typography variant="h3">Informações de pagamento</Typography>}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item md={4} xs={12}>
            <Grid
              alignItems="center"
              aria-haspopup="true"
              aria-owns={popoverIsOpen ? 'mouse-over-popover' : undefined}
              classes={{
                root: clsx(classes.paper, {
                  [classes.paperDisabled]: customersWithoutAddress.length > 0,
                }),
              }}
              component={Paper}
              container
              justify="center"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <Typography variant="h5">Boleto Bancário</Typography>
              <Checkbox
                checked={payment_types_accepted.billet}
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                color="default"
                disabled={customersWithoutAddress.length > 0}
                name="billet"
                onChange={handleChange}
                value="true"
              />
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid
              alignItems="center"
              classes={{
                root: classes.paperSecondary,
              }}
              component={Paper}
              container
              justify="center"
            >
              <Typography variant="h5">Cartão de Crédito</Typography>
              <Checkbox
                checked={payment_types_accepted.credit_card}
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                color="default"
                name="credit_card"
                onChange={handleChange}
                value="true"
              />
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <InputCurrency
              fullWidth
              id="amount"
              label="Valor da cobrança"
              name="amount"
              onChange={handleChange}
              type="tel"
              value={amount}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginTop} container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              FormHelperTextProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              InputLabelProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              SelectProps={{
                classes: {
                  icon: classes.icon,
                  disabled: classes.disabled,
                },
                MenuProps: {
                  classes: {
                    paper: classes.menuList,
                  },
                },
              }}
              disabled={amount === 0}
              fullWidth
              helperText="O valor da cobrança deve ser maior que R$ 0,00"
              id="installments"
              label="Número de parcelas"
              name="installments"
              onChange={handleChange}
              select
              value={installments}
              variant="outlined"
            >
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={1}
              >{`1 parcela de R$ ${getDisplayMoney(amount)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={2}
              >{`2 parcela de R$ ${getDisplayMoney(amount / 2)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={3}
              >{`3 parcela de R$ ${getDisplayMoney(amount / 3)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={4}
              >{`4 parcela de R$ ${getDisplayMoney(amount / 4)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={5}
              >{`5 parcela de R$ ${getDisplayMoney(amount / 5)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={6}
              >{`6 parcela de R$ ${getDisplayMoney(amount / 6)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={7}
              >{`7 parcela de R$ ${getDisplayMoney(amount / 7)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={8}
              >{`8 parcela de R$ ${getDisplayMoney(amount / 8)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={9}
              >{`9 parcela de R$ ${getDisplayMoney(amount / 9)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={10}
              >{`10 parcela de R$ ${getDisplayMoney(amount / 10)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={11}
              >{`11 parcela de R$ ${getDisplayMoney(amount / 11)}`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={12}
              >{`12 parcela de R$ ${getDisplayMoney(amount / 12)}`}</MenuItem>
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              locale="pt-br"
              utils={MomentUtils}
            >
              <DatePicker
                DialogProps={{
                  PaperProps: {
                    classes: {
                      root: classes.rootDialog,
                    },
                  },
                }}
                cancelLabel="CANCELAR"
                disablePast
                format="DD/MM/YYYY"
                fullWidth
                helperText="O pagamento é válido até 23:59:59 da data selecionada"
                id="expiration_date"
                inputVariant="outlined"
                label="Data de Vencimento"
                leftArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                name="expiration_date"
                onChange={handleChangeExpirationDate}
                rightArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                value={expiration_date}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader
        action={
          <Switch
            checked={expiration_options}
            color="primary"
            id="expiration_options"
            name="expiration_options"
            onChange={handleChange}
          />
        }
        classes={{
          action: classes.cardHeaderAction,
        }}
        subheader={
          <Typography
            className={classes.subTitle}
            color="textSecondary"
            variant="h5"
          >
            Escolha se você quer receber após a data de vencimento e se você
            quer receber multas e/ou juros pelo atraso.
          </Typography>
        }
        title={<Typography variant="h3">Opções de vencimento</Typography>}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              FormHelperTextProps={{
                disabled: classes.disabled,
              }}
              InputLabelProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              SelectProps={{
                classes: {
                  icon: classes.icon,
                  disabled: classes.disabled,
                },
                MenuProps: {
                  classes: {
                    paper: classes.menuList,
                  },
                },
              }}
              disabled={!expiration_options}
              fullWidth
              id="payment_limit_date"
              label="Prazo máximo após a data de vencimento"
              name="payment_limit_date"
              onChange={handleChange}
              select
              value={payment_limit_date || ''}
              variant="outlined"
            >
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={3}
              >
                até 3 dias
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={5}
              >
                até 5 dias
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={7}
              >
                até 7 dias
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={10}
              >
                até 10 dias
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={15}
              >
                até 15 dias
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={20}
              >
                até 20 dias
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={25}
              >
                até 25 dias
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={29}
              >
                até 29 dias
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              FormHelperTextProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              InputLabelProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              SelectProps={{
                classes: {
                  icon: classes.icon,
                  disabled: classes.disabled,
                },
                MenuProps: {
                  classes: {
                    paper: classes.menuList,
                  },
                },
              }}
              disabled={!expiration_options || amount === 0}
              fullWidth
              helperText="O valor da cobrança deve ser maior que R$ 0,00"
              id="late_fee_percentage"
              label="Multa pelo atrasado do pagamento (%)"
              name="late_fee_percentage"
              onChange={handleChange}
              select
              value={late_fee.percentage || ''}
              variant="outlined"
            >
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={0.25}
              >{`0.25% do valor (${getDisplayMoney(
                (amount / installments) * 0.0025
              )})`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={0.5}
              >{`0.5% do valor (${getDisplayMoney(
                (amount / installments) * 0.005
              )})`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={0.75}
              >{`0.75% do valor (${getDisplayMoney(
                (amount / installments) * 0.0075
              )})`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={1}
              >{`1% do valor (${getDisplayMoney(
                (amount / installments) * 0.01
              )})`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={1.25}
              >{`1.25% do valor (${getDisplayMoney(
                (amount / installments) * 0.0125
              )})`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={1.5}
              >{`1.5% do valor (${getDisplayMoney(
                (amount / installments) * 0.015
              )})`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={1.75}
              >{`1.75% do valor (${getDisplayMoney(
                (amount / installments) * 0.0175
              )})`}</MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={2}
              >{`2% do valor (${getDisplayMoney(
                (amount / installments) * 0.02
              )}) (Máximo permitido por lei)`}</MenuItem>
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              FormHelperTextProps={{
                disabled: classes.disabled,
              }}
              InputLabelProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              SelectProps={{
                classes: {
                  icon: classes.icon,
                  disabled: classes.disabled,
                },
                MenuProps: {
                  classes: {
                    paper: classes.menuList,
                  },
                },
              }}
              disabled={!expiration_options}
              fullWidth
              id="interest_percentage"
              label="Juros pelo atrasado do pagamento (%)"
              name="interest_percentage"
              onChange={handleChange}
              select
              value={interest.percentage || ''}
              variant="outlined"
            >
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={0.25}
              >
                0.25% ao mês
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={0.5}
              >
                0.5% ao mês
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={0.75}
              >
                0.75% ao mês
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={1}
              >
                1% ao mês (Máximo permitido por lei)
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader
        action={
          <Switch
            checked={discount_options}
            color="primary"
            id="discount_options"
            name="discount_options"
            onChange={handleChange}
          />
        }
        classes={{
          action: classes.cardHeaderAction,
        }}
        subheader={
          <Typography
            className={classes.subTitle}
            color="textSecondary"
            variant="h5"
          >
            Escolha se você quer dar desconto antes da data de vencimento.
          </Typography>
        }
        title={<Typography variant="h3">Opções de desconto</Typography>}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              FormHelperTextProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              InputLabelProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              SelectProps={{
                classes: {
                  icon: classes.icon,
                  disabled: classes.disabled,
                },
                MenuProps: {
                  classes: {
                    paper: classes.menuList,
                  },
                },
              }}
              disabled={!discount_options}
              fullWidth
              helperText="O desconto é valido até o às 23:59:59 do dia selecionado"
              id="discount_limit_date"
              label="Prazo máximo do desconto"
              name="discount_limit_date"
              onChange={handleChange}
              select
              value={
                discount.limit_date || discount.limit_date === 0
                  ? discount.limit_date
                  : ''
              }
              variant="outlined"
            >
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={0}
              >
                Até a data de vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={1}
              >
                Até 1 dia antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={2}
              >
                Até 2 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={3}
              >
                Até 3 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={4}
              >
                Até 4 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={5}
              >
                Até 5 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={6}
              >
                Até 6 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={7}
              >
                Até 7 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={8}
              >
                Até 8 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={9}
              >
                Até 9 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={10}
              >
                Até 10 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={11}
              >
                Até 11 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={12}
              >
                Até 12 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={13}
              >
                Até 13 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={14}
              >
                Até 14 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={15}
              >
                Até 15 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={16}
              >
                Até 16 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={17}
              >
                Até 17 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={18}
              >
                Até 18 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={19}
              >
                Até 19 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={20}
              >
                Até 20 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={21}
              >
                Até 21 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={22}
              >
                Até 22 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={23}
              >
                Até 23 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={24}
              >
                Até 24 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={25}
              >
                Até 25 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={26}
              >
                Até 26 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={27}
              >
                Até 27 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={28}
              >
                Até 28 dias antes do vencimento
              </MenuItem>
              <MenuItem
                ListItemClasses={{
                  root: classes.menuItem,
                  selected: classes.itemSelected,
                }}
                value={29}
              >
                Até 29 dias antes do vencimento
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <InputCurrency
              disabled={!discount_options}
              fullWidth
              helperText="O valor do desconto é aplicado em cada parcela"
              id="discount_amount"
              label="Valor do desconto"
              name="discount_amount"
              onChange={handleChange}
              type="tel"
              value={discount.amount}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </React.Fragment>
  );
};

export default ChargeConfig;
