import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import {
  chargeNextStep,
  chargePreviousStep,
  resetCharge,
  setChargeCustomers,
  setChargeDisabledButtons,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
  setListCustomersSelected,
} from 'store/actions';

import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Grow,
} from '@material-ui/core';

import { Customer } from 'views';

import { ChargeConfig, ConfirmCharge } from './components';
import { getDisplayMoney } from 'helpers';
import { api } from 'services/api';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  cardContent: {
    padding: 0,
  },
  buttonPrevious: {
    [theme.breakpoints.down('sm')]: {
      order: 0,
    },
  },
  buttonNext: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  stepper: {
    flex: 1,
    backgroundColor: theme.palette.background.dark2,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  containerCardActions: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
}));

const StepComponents = [
  <Customer
    key="customer-view"
    textSecondary="Selecione os clientes que você deseja cobrar"
  />,
  <ChargeConfig key="config-view" />,
  <ConfirmCharge key="confirm-view" />,
];

const Charge = ({ history }) => {
  const classes = useStyles();
  const {
    steps,
    currentStep,
    chargeCustomers,
    payment_types_accepted,
    installments,
    expiration_date,
    payment_limit_date,
    expiration_options,
    discount_options,
    late_fee,
    interest,
    discount,
    amount,
    disabledButtons,
  } = useSelector(state => state.chargeState);
  const { listCustomersSelected } = useSelector(state => state.customerState);
  const dispatch = useDispatch();

  const validateStepOne = () => {
    if (listCustomersSelected.length === 0) {
      dispatch(
        setSnackbarMessage(
          'Nenhum cliente selecionado. Selecione no mínimo 1 cliente !'
        )
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
      return false;
    } else {
      return true;
    }
  };

  const validateStepTwo = () => {
    const dateDiscount = moment(expiration_date)
      .subtract(discount.limit_date, 'days')
      .endOf('day');
    if (!payment_types_accepted.billet && !payment_types_accepted.credit_card) {
      dispatch(
        setSnackbarMessage(
          'Você deve selecionar pelo menos um tipo de pagamento (boleto ou cartão de crédito)'
        )
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
      return false;
    } else if (amount === 0 || amount < 4.0) {
      dispatch(
        setSnackbarMessage('O preço da cobrança deve ser maior que R$ 3,99')
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
      return false;
    } else if (expiration_options && !payment_limit_date) {
      dispatch(
        setSnackbarMessage(
          'Se você habilitar as opções de vencimento, deverá ser informado o prazo máximo após a data de vencimento'
        )
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
      return false;
    } else if (discount_options && moment().isAfter(dateDiscount)) {
      dispatch(
        setSnackbarMessage(
          `A data de desconto selecionada é ${dateDiscount.format(
            'DD/MM/YYYY HH:mm:ss'
          )} e não pode ser antes de ${moment().format('DD/MM/YYYY HH:mm:ss')}`
        )
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
      return false;
    } else if (
      discount_options &&
      !discount.limit_date &&
      discount.limit_date !== 0
    ) {
      dispatch(
        setSnackbarMessage(
          'Você deve selecionar o prazo máximo de desconto se a opções de desconto estiver ativa'
        )
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
      return false;
    } else if (discount_options && discount.amount === 0) {
      dispatch(
        setSnackbarMessage(
          'O valor de desconto deve ser maior que R$ 0,00 se a opções de desconto estiver ativa'
        )
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
      return false;
    } else if (discount_options && discount.amount >= amount / installments) {
      dispatch(
        setSnackbarMessage(
          `O valor de desconto não deve ser maior que ${getDisplayMoney(
            amount / installments
          )} se a opções de desconto estiver ativa`
        )
      );
      dispatch(setSnackbarType('warning'));
      dispatch(setSnackbarOpen());
    } else {
      return true;
    }
  };

  const handleNextStep = () => {
    if (currentStep === 0) {
      if (validateStepOne()) {
        dispatch(setChargeCustomers(listCustomersSelected));
      } else {
        return;
      }
    } else if (currentStep === 1) {
      if (!validateStepTwo()) {
        return;
      }
    } else if (currentStep === 2) {
      handleSubmit();
      return;
    }

    dispatch(chargeNextStep());
  };

  const handlePreviousStep = () => {
    dispatch(chargePreviousStep());
  };

  const handleSubmit = async () => {
    dispatch(setSnackbarMessage('Processando...'));
    dispatch(setSnackbarType('info'));
    dispatch(setSnackbarOpen());

    dispatch(setChargeDisabledButtons(true));

    const token = localStorage.getItem('token');
    const arrayInstallments = [];
    const errors = [];

    for (let index = 1; index <= installments; index++) {
      arrayInstallments.push(index);
    }

    for (const customer of chargeCustomers) {
      try {
        var response_group_charge = await api.post(
          '/group_charge/',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        errors.push({
          type: 'group_charge',
          customer: customer.name,
        });
        console.log(error);
        continue;
      }

      const { id } = response_group_charge.data;

      for (const installment of arrayInstallments) {
        const data = {
          buyer_id: customer.id,
          group_charge_id: id,
          payment_types_accepted,
          installment,
          installments,
          currency: 'BRL',
          expiration_date: moment(expiration_date)
            .add(installment - 1, 'month')
            .utc()
            .format(),
          payment_limit_date: payment_limit_date
            ? moment(expiration_date)
                .add(installment - 1, 'month')
                .utc()
                .add(payment_limit_date, 'days')
                .format()
            : null,
          late_fee_mode: late_fee.mode,
          late_fee_percentage: late_fee.percentage,
          interest_mode: interest.mode,
          interest_percentage: interest.percentage,
          discount_mode: discount.mode,
          discount_limit_date:
            discount.limit_date || discount.limit_date === 0
              ? moment(expiration_date)
                  .add(installment - 1, 'month')
                  .utc()
                  .subtract(discount.limit_date, 'days')
                  .format()
              : null,
          discount_amount: discount.amount * 100,
          amount: (amount / installments) * 100,
          original_amount: amount * 100,
        };

        try {
          const response_charge = await api.post('/charge/', data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log(response_charge.data);
        } catch (error) {
          errors.push({
            type: 'charge',
            number_charge: installment,
            customer: customer.name,
          });
          console.log(error);
        }
      }
    }

    if (errors.length === 0) {
      dispatch(setSnackbarMessage('Cobranças criadas com sucesso !'));
      dispatch(setSnackbarType('success'));
      dispatch(setSnackbarOpen());
      dispatch(resetCharge());
      history.push('charge-list');
    }

    dispatch(setListCustomersSelected([]));
  };

  const renderComponentStep = () => {
    return StepComponents[currentStep];
  };

  const renderSteps = () => {
    return steps.map(step => (
      <Step key={step}>
        <StepLabel>{step}</StepLabel>
      </Step>
    ));
  };

  return (
    <Grow in>
      <Card className={classes.root}>
        {renderComponentStep()}
        <CardActions>
          <Grid
            alignItems="center"
            className={classes.containerCardActions}
            container
            justify="space-evenly"
          >
            <Button
              className={classes.buttonPrevious}
              color="primary"
              disabled={!!(currentStep === 0 || disabledButtons)}
              onClick={handlePreviousStep}
              variant="outlined"
            >
              Voltar
            </Button>
            <Stepper
              activeStep={currentStep}
              alternativeLabel
              className={classes.stepper}
            >
              {renderSteps()}
            </Stepper>
            <Button
              className={classes.buttonNext}
              color="primary"
              disabled={!!(currentStep === steps.length || disabledButtons)}
              onClick={handleNextStep}
              variant="contained"
            >
              {currentStep === 2 ? 'Finalizar' : 'Avançar'}
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Grow>
  );
};

export default Charge;
