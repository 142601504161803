import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as MarketplaceUserHooks from 'hooks/use-marketplace-user';

import * as InternalComponent from './components';
import useStyles from './marketplace-user.styles';

function MarketplaceUser() {
  const classes = useStyles();

  const coupon = MarketplaceUserHooks.useMarketplaceUser();

  return (
    <Mui.Card className={classes.root}>
      <Mui.CardHeader
        action={
          <Mui.IconButton onClick={coupon.ui.refresh}>
            <MuiIcons.Refresh />
          </Mui.IconButton>
        }
        subheader={
          <Mui.Typography color="textSecondary" variant="h5">
            Gerencie seus usuários
          </Mui.Typography>
        }
        title={<Mui.Typography variant="h3">Usuários</Mui.Typography>}
      />
      <Mui.Divider />
      <Mui.CardContent className={classes.cardContent}>
        <InternalComponent.MarketplaceUserToolbar />
        <InternalComponent.MarketplaceUserList />
      </Mui.CardContent>
    </Mui.Card>
  );
}

export default MarketplaceUser;
