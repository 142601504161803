export enum ETaxInvoiceConfigProvider {
  ENOTAS = 'enotas',
  NFE_IO = 'nfe.io',
  NOTAZZ = 'notazz',
  RECEBAFACIL = 'recebafacil',
}

type TProvider = `${ETaxInvoiceConfigProvider}`;

interface IInternalMetadata {
  notazzApiKey?: string;
}

export interface ITaxInvoiceConfig<Metadata = object> {
  id: string;
  resource: 'tax-invoice-config';
  marketplaceId: string;
  sellerId: string;
  provider: TProvider;
  active: boolean;
  internalMetadata: IInternalMetadata | null;
  metadata: Metadata | null;
  modified: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
