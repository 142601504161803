import React from 'react';
import * as ReactRedux from 'react-redux';

import type { ICheckout } from 'types/entities';
import type { ICheckoutReducerState } from 'types/redux';
import type { IResourceListInterface } from 'types/services';

import { api } from 'services/api';
import { checkoutAPI } from 'services/checkoutAPI';
import {
  setCheckout,
  setCheckoutPagination,
  setCheckoutState,
  setDialogClose,
  setDialogType,
  setListCheckouts,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import { useAuthContext } from '../useAuthContext.hook';
import type { ICheckoutProduct } from 'types/entities/checkout';

interface ICheckoutServiceGetAll {
  limit?: number;
  offset?: number;
}

interface IUseCheckoutService {
  create: {
    name: ICheckout['name'];
    checkoutProductType: ICheckoutProduct['type'];
    paymentTypesAccepted: ICheckout['paymentTypesAccepted'];
    general: ICheckout['redirectUrl']['general'];
    productId: string;
    offerId: string | null;
    metadata: object | null;
    internalMetadata: ICheckout['internalMetadata'] | null;
  };
  edit: {
    checkoutId: string;
    name: ICheckout['name'];
    paymentTypesAccepted: ICheckout['paymentTypesAccepted'];
    general: ICheckout['redirectUrl']['general'];
    metadata: object | null;
    internalMetadata: ICheckout['internalMetadata'] | null;
  };
}

function useCheckoutService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id: marketplaceId, id } = user;

  const getAll = React.useCallback(
    async (props?: ICheckoutServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<IResourceListInterface<ICheckout>>(
          `v2/marketplaces/${marketplaceId}/checkouts`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: props?.limit,
              offset: props?.offset,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: checkouts,
          total,
        } = response.data;

        const pagination: ICheckoutReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListCheckouts(checkouts),
          setCheckoutState('success'),
          setCheckoutPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem dos checkouts, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de checkouts');
        throw error;
      }
    },
    [dispatch, marketplaceId]
  );

  const create = React.useCallback(
    async (params: IUseCheckoutService['create']) => {
      try {
        const {
          name,
          paymentTypesAccepted,
          checkoutProductType,
          general,
          productId,
          offerId,
          metadata,
          internalMetadata,
        } = params;

        const response = await api.post<ICheckout>(
          `v2/marketplaces/${marketplaceId}/checkouts`,
          {
            sellerId: id,
            name,
            paymentTypesAccepted,
            redirectUrl: {
              general,
              boleto: null,
              credit: null,
              pix: null,
            },
            metadata,
            internalMetadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        const checkout = response.data;

        await api.post(
          `v2/marketplaces/${marketplaceId}/checkouts/${checkout.id}/products`,
          {
            sellerId: id,
            productId: productId,
            offerId: offerId,
            type: checkoutProductType,
            name,
            upsell: false,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setCheckoutState('get'),
          setCheckout(null),
          setSnackbarMessage('Checkout criado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar este checkout, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, id, marketplaceId]
  );

  const edit = React.useCallback(
    async (params: IUseCheckoutService['edit']) => {
      try {
        const {
          name,
          checkoutId,
          paymentTypesAccepted,
          general,
          metadata,
          internalMetadata,
        } = params;

        await api.put(
          `v2/marketplaces/${marketplaceId}/checkouts/${checkoutId}`,
          {
            name,
            paymentTypesAccepted: paymentTypesAccepted,
            redirectUrl: {
              general,
              boleto: null,
              credit: null,
              pix: null,
            },
            internalMetadata,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        await checkoutAPI.get(`/api/revalidate?id=${checkoutId}`);

        dispatch([
          setCheckoutState('get'),
          setCheckout(null),
          setSnackbarMessage('Checkout editado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar este checkout, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplaceId]
  );

  return React.useMemo(
    () => ({
      getAll,
      create,
      edit,
    }),
    [getAll, create, edit]
  );
}

export default useCheckoutService;
