import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import PlanReducer from 'store/reducers/PlanReducer';
import BankAccountReducer from './reducers/BankAccountReducer';
import ChargeReducer from './reducers/ChargeReducer';
import CheckoutReducer from './reducers/CheckoutReducer';
import CouponReducer from './reducers/CouponReducer';
import CustomerReducer from './reducers/CustomerReducer';
import DashboardReducer from './reducers/DashboardReducer';
import DialogReducer from './reducers/DialogReducer';
import FutureTransfersReducer from './reducers/FutureTransfersReducer';
import marketplaceUserReducer from './reducers/marketplace-user-reducer';
import NotificationsReducer from './reducers/NotificationsReducer';
import OfferReducer from './reducers/OfferReducer';
import ProductReducer from './reducers/ProductReducer';
import SaleReducer from './reducers/SaleReducer';
import SalesPlanReducer from './reducers/SalesPlanReducer';
import SnackbarReducer from './reducers/SnackbarReducer';
import SubscriptionReducer from './reducers/SubscriptionReducer';
import taxInvoiceConfigReducer from './reducers/tax-invoice-config-reducer';
import taxInvoiceReducer from './reducers/tax-invoice-reducer';

const rootReducer = combineReducers({
  bankAccountState: BankAccountReducer,
  chargeState: ChargeReducer,
  checkoutState: CheckoutReducer,
  couponState: CouponReducer,
  customerState: CustomerReducer,
  dashboardState: DashboardReducer,
  dialogState: DialogReducer,
  form: FormReducer,
  futureTransfersState: FutureTransfersReducer,
  notificationsState: NotificationsReducer,
  offerState: OfferReducer,
  taxInvoiceState: taxInvoiceReducer,
  taxInvoiceConfigState: taxInvoiceConfigReducer,
  productState: ProductReducer,
  salesPlanState: SalesPlanReducer,
  saleState: SaleReducer,
  snackbarState: SnackbarReducer,
  subscriptionState: SubscriptionReducer,
  planState: PlanReducer,
  marketplaceUser: marketplaceUserReducer,
});

export default rootReducer;
