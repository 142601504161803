import type { ICheckoutProduct } from 'types/entities/checkout';

export class CheckoutUtils {
  public static checkoutProductTypeLabelMap: Record<
    ICheckoutProduct['type'],
    string
  > = {
    product: 'Produto',
    plan: 'Assinatura',
  };

  public static getCheckoutProductTypeLabel(
    type: ICheckoutProduct['type']
  ): string {
    return this.checkoutProductTypeLabelMap[type] || '';
  }
}
