import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IResourceListInterface } from 'types/services';
import type { ISubscription } from 'types/entities';
import type { ISubscriptionReducerState } from 'types/redux';
import { api } from 'services/api';
import {
  setListSubscriptions,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
  setSubscriptionPagination,
  setSubscriptionState,
} from 'store/actions';

import { useAuthContext } from '../useAuthContext.hook';

interface ISubscriptionServiceGetAll {
  limit?: number;
  offset?: number;
}

function useSubscriptionService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id } = user;

  const getAll = React.useCallback(
    async (props?: ISubscriptionServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<IResourceListInterface<ISubscription>>(
          `/marketplaces/${marketplace_id}/subscriptions`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: props?.limit,
              offset: props?.offset,
              populate: 'customer,plan',
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: subscriptions,
          total,
        } = response.data;

        const pagination: ISubscriptionReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListSubscriptions(subscriptions),
          setSubscriptionState('success'),
          setSubscriptionPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem das assinaturas, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de assinaturas');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  return React.useMemo(
    () => ({
      getAll,
    }),
    [getAll]
  );
}

export default useSubscriptionService;
