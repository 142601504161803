import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import * as ReactRedux from 'react-redux';

import { getDisplayDocumentNumber, getDisplayPhoneNumber } from 'helpers';
import { setCoupon, setDialogClose, setDialogType } from 'store/actions';
import type { RootState } from 'types/redux';

import { MarketplaceUserUtils } from 'helpers/marketplace-user-utils';
import type { EMarketplaceUserRole } from 'types/entities/marketplace-user';
import useStyles from './marketplace-user-view-dialog.styles';
import * as MarketplaceUserHooks from 'hooks/use-marketplace-user';
function MarketplaceUserViewDialog() {
  const classes = useStyles();
  const dispatch = ReactRedux.useDispatch();
  const { service } = MarketplaceUserHooks.useMarketplaceUser();
  const [sendingEmail, setSendingEmail] = React.useState<boolean>(false);

  const { marketplaceUser } = ReactRedux.useSelector(
    (state: RootState) => state.marketplaceUser
  );

  if (!marketplaceUser) {
    return null;
  }

  const metadata = Object.entries(marketplaceUser.metadata || {});

  function handleDialogClose() {
    dispatch([setCoupon(null), setDialogClose(), setDialogType(null)]);
  }

  async function handleSendEmail() {
    if (!marketplaceUser) return;

    setSendingEmail(true);
    await service.sendEmail({ id: marketplaceUser.id });
    setSendingEmail(false);
  }

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            {`Informações de ${marketplaceUser.name}`}
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="end"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>

        <div className={classes.actionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                DELETAR
              </Mui.Button>
            </div>
          </Mui.Tooltip>
          <div className={classes.actionButton}>
            <Mui.Button
              color="primary"
              disabled={sendingEmail}
              fullWidth
              onClick={handleSendEmail}
              variant="contained"
            >
              REENVIAR CONVITE
            </Mui.Button>
          </div>
        </div>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do usuário</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Nome: </strong>
            {marketplaceUser.name}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Email: </strong>
            {marketplaceUser.email}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Número do documento: </strong>
            {getDisplayDocumentNumber(marketplaceUser.documentNumber)}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Telefone: </strong>
            {getDisplayPhoneNumber(marketplaceUser.phoneNumber)}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de criação: </strong>
            {moment(marketplaceUser.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Última atualização: </strong>
            {moment(marketplaceUser.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Cargos: </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.List className={classes.checkboxList}>
            {Object.entries(MarketplaceUserUtils.rolesLabelMap).map(
              ([value, label]) => (
                <Mui.ListItem
                  button
                  className={classes.checkboxListItem}
                  disabled
                  key={value}
                >
                  <Mui.Checkbox
                    checked={marketplaceUser.roles.includes(
                      value as EMarketplaceUserRole
                    )}
                    color="primary"
                    name={value}
                  />
                  <Mui.ListItemText primary={label} />
                </Mui.ListItem>
              )
            )}
          </Mui.List>
        </Mui.Grid>
        {metadata.length > 0 && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Metadados</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container direction="column" item spacing={1}>
              {metadata.map(([key, value]) => (
                <Mui.Grid item key={key} xs={4}>
                  <Mui.Typography variant="body1">
                    <strong>{key}: </strong>
                    {value}
                  </Mui.Typography>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
}

export default MarketplaceUserViewDialog;
