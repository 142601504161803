import React from 'react';
import * as ReactRedux from 'react-redux';
import { validate } from 'validate.js';

import { PromiseUtils } from 'helpers';
import { useAuthContext } from 'hooks/useAuthContext.hook';
import * as CheckoutHooks from 'hooks/useCheckout';
import { api } from 'services/api';
import {
  setCheckout,
  setDialogClose,
  setDialogType,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import type { IOffer, IPlan } from 'types/entities';
import type { ICheckoutProduct } from 'types/entities/checkout';
import type { IResourceListInterface } from 'types/services';

type TPaymentTypeAccepted = keyof Pick<
  IFormState['values'],
  'boleto' | 'credit' | 'pix'
>;

interface IFormState {
  isValid: boolean;
  values: {
    name: string;
    general: string;
    boleto: boolean;
    pix: boolean;
    credit: boolean;
    offer: IOffer | null;
    plan: IPlan | null;
    planDueDate: string | null;
    checkoutProductType: ICheckoutProduct['type'];
    metadata: Record<string, string | number> | null;
  };
  errors: {
    name?: string;
    general?: string;
    boleto?: string;
    pix?: string;
    credit?: string;
    offer?: string;
    plan?: string;
    planDueDate?: string;
    checkoutProductType?: string;
    metadata?: string;
  };
  touched: {
    name?: boolean;
    general?: boolean;
    boleto?: boolean;
    pix?: boolean;
    credit?: boolean;
    offer?: boolean;
    plan?: boolean;
    planDueDate?: boolean;
    checkoutProductType?: boolean;
    metadata?: boolean;
  };
}

interface ILinkCopied {
  checkout: boolean;
  redeem: boolean;
}

const schema = {
  general: {
    presence: {
      allowEmpty: false,
      message: '^URL de redirecionamento é obrigatório',
    },
  },
};

export function useCheckoutEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();
  const { marketplace_id } = user;

  const { state, service } = CheckoutHooks.useCheckout();

  const { checkout, state: checkoutState } = state;

  const [formState, setFormState] = React.useState<IFormState>({
    isValid: false,
    values: {
      name: checkout?.name || '',
      general: checkout?.redirectUrl.general || '',
      boleto: checkout?.paymentTypesAccepted.includes('boleto') || false,
      pix: checkout?.paymentTypesAccepted.includes('pix') || false,
      credit: checkout?.paymentTypesAccepted.includes('credit') || false,
      offer: null,
      plan: null,
      planDueDate: null,
      checkoutProductType: 'product',
      metadata: null,
    },
    errors: {},
    touched: {
      checkoutProductType: true,
      boleto: true,
      credit: true,
      pix: true,
      plan: true,
      offer: true,
    },
  });
  const [plans, setPlans] = React.useState<IPlan[]>([]);
  const [offers, setOffers] = React.useState<IOffer[]>([]);
  const [linkCopied, setLinkCopied] = React.useState<ILinkCopied>({
    checkout: false,
    redeem: false,
  });

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    },
    []
  );

  const handleProductChange = React.useCallback(
    (event: React.ChangeEvent<object>, plan: IPlan | null) => {
      event.persist();

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          plan,
        },
      }));
    },
    []
  );

  const handleOfferChange = React.useCallback(
    (event: React.ChangeEvent<object>, offer: IOffer | null) => {
      event.persist();

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          offer,
        },
      }));
    },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!formState.touched[field] && !!formState.errors[field];
    },
    [formState.errors, formState.touched]
  );

  function handleInputFocus(inputName: keyof IFormState['values']) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;
    inputElement.focus();
  }

  const handleSubmit = React.useCallback(async () => {
    setFormState(prevState => ({
      ...prevState,
      errors: {},
    }));

    const {
      name,
      boleto,
      credit,
      pix,
      plan,
      planDueDate,
      offer,
      metadata,
      checkoutProductType,
    } = formState.values;
    let { general } = formState.values;

    general = general.trim();

    if (!boleto && !credit && !pix) {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          credit: 'É necessário preencher um método de pagamento',
        },
      }));

      return;
    }

    if (general === '') {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          general: 'URL de redirecionamento é obrigatório',
        },
      }));

      handleInputFocus('general');
      return;
    }

    if (checkoutProductType === 'plan' && !plan) {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          plan: 'Selecione um plano',
        },
      }));

      handleInputFocus('plan');
      return;
    }

    if (checkoutProductType === 'product' && !offer) {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          offer: 'Selecione uma oferta',
        },
      }));

      handleInputFocus('offer');
      return;
    }

    const paymentTypesAccepted: TPaymentTypeAccepted[] = [];

    if (boleto) {
      paymentTypesAccepted.push('boleto');
    }

    if (credit) {
      paymentTypesAccepted.push('credit');
    }

    if (pix) {
      paymentTypesAccepted.push('pix');
    }

    if (checkoutState === 'create') {
      await service.create({
        name,
        offerId:
          formState.values.checkoutProductType === 'product' ? offer!.id : null,
        general,
        paymentTypesAccepted,
        checkoutProductType,
        productId:
          formState.values.checkoutProductType === 'plan'
            ? plan!.id
            : offer!.productId,
        metadata,
        internalMetadata: {
          planDueDate: Number(planDueDate),
        },
      });
    }

    if (checkoutState === 'edit') {
      await service.edit({
        name,
        checkoutId: checkout!.id,
        general,
        metadata,
        paymentTypesAccepted,
        internalMetadata: {
          planDueDate: Number(planDueDate),
        },
      });
    }
  }, [checkout, checkoutState, formState.values, service]);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setCheckout(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  const handleCheckboxChange = React.useCallback(
    (inputName: TPaymentTypeAccepted) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          [inputName]: !prevState.values[inputName],
        },
        touched: {
          ...prevState.touched,
          [inputName]: true,
        },
      }));
    },
    []
  );

  const handleSelectAllText = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      try {
        const inputElement = (
          event as unknown as React.ChangeEvent<HTMLInputElement>
        ).target;

        inputElement.select();
        inputElement.setSelectionRange(0, 99999);
      } catch (error) {
        console.error('Não foi possível copiar o texto');
      }
    },
    []
  );

  const handleCopyToClipBoard = React.useCallback(
    async (linkType: keyof ILinkCopied) => {
      let inputElement: HTMLInputElement;

      if (linkType === 'checkout') {
        inputElement = document.querySelector(
          '#checkout-link'
        ) as HTMLInputElement;
      }

      if (linkType === 'redeem') {
        inputElement = document.querySelector(
          '#redeem-link'
        ) as HTMLInputElement;
      }

      if (!inputElement!) {
        return;
      }

      inputElement.select();
      inputElement.setSelectionRange(0, 99999);
      await navigator.clipboard.writeText(inputElement.value);

      setLinkCopied(prevState => ({
        ...prevState,
        [linkType]: true,
      }));

      dispatch([
        setSnackbarType('info'),
        setSnackbarMessage('Copiado para área de transferência'),
        setSnackbarOpen(),
      ]);

      await PromiseUtils.awaiter(3000);

      setLinkCopied(prevState => ({
        ...prevState,
        [linkType]: false,
      }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  React.useEffect(() => {
    if (
      checkoutState === 'create' &&
      formState.values.checkoutProductType === 'plan'
    ) {
      api
        .get<IResourceListInterface<IPlan>>(
          `v2/marketplaces/${marketplace_id}/plans`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: 1000,
              offset: 0,
            },
          }
        )
        .then(response => {
          const result = response.data;

          setPlans(result.items);
        })
        .catch(error => {
          console.error(error);
        });
    }

    if (
      checkoutState === 'create' &&
      formState.values.checkoutProductType === 'product'
    ) {
      api
        .get<IResourceListInterface<IOffer>>(
          `v2/marketplaces/${marketplace_id}/offers`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: 1000,
              offset: 0,
            },
          }
        )
        .then(response => {
          const result = response.data;

          setOffers(result.items);
        })
        .catch(error => {
          console.error(error);
        });
    }

    if (checkoutState === 'edit') {
      api
        .get<IResourceListInterface<ICheckoutProduct>>(
          `v2/marketplaces/${marketplace_id}/checkouts/${
            checkout!.id
          }/products`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        )
        .then(response => {
          const result = response.data;

          if (result.items.length === 0) {
            return;
          }

          const checkoutProduct = result.items[0];

          if (checkoutProduct.type === 'product') {
            api
              .get<IOffer>(
                `v2/marketplaces/${marketplace_id}/offers/${checkoutProduct.offerId}`,
                {
                  auth: {
                    username: process.env.REACT_APP_BYPASS_API_KEY!,
                    password: '',
                  },
                  params: {
                    limit: 1000,
                    offset: 0,
                  },
                }
              )
              .then(response => {
                const offer = response.data;

                setOffers([offer]);
                setFormState(prevState => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    offer,
                    checkoutProductType: checkoutProduct.type,
                  },
                }));
              })
              .catch(error => {
                console.error(error);
              });
          }

          if (checkoutProduct.type === 'plan') {
            api
              .get<IPlan>(
                `v2/marketplaces/${marketplace_id}/plans/${checkoutProduct.productId}`,
                {
                  auth: {
                    username: process.env.REACT_APP_BYPASS_API_KEY!,
                    password: '',
                  },
                  params: {
                    limit: 1000,
                    offset: 0,
                  },
                }
              )
              .then(response => {
                const plan = response.data;

                setPlans([plan]);
                setFormState(prevState => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    plan,
                    checkoutProductType: checkoutProduct.type,
                    planDueDate:
                      checkout?.internalMetadata?.planDueDate?.toString() ||
                      null,
                  },
                }));
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [
    checkout,
    checkoutState,
    formState.values.checkoutProductType,
    marketplace_id,
  ]);

  return React.useMemo(
    () => ({
      checkout,
      checkoutState,
      formState,
      linkCopied,
      offers,
      plans,
      handleCheckboxChange,
      handleCopyToClipBoard,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleOfferChange,
      handleProductChange,
      handleSelectAllText,
      handleSubmit,
      hasError,
    }),
    [
      checkout,
      checkoutState,
      formState,
      linkCopied,
      offers,
      plans,
      handleCheckboxChange,
      handleCopyToClipBoard,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleOfferChange,
      handleProductChange,
      handleSelectAllText,
      handleSubmit,
      hasError,
    ]
  );
}
